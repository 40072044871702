@font-face {
  font-family: "Montserrat";
  src: url("../public/fonts/Montserrat/Montserrat-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../public/fonts/Roboto/Roboto-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}


/* General body styling */
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  background-color: #000;
  color: #fff;
  overflow-x: hidden;
}

body p{
  font-family: "Montserrat", sans-serif;
}
.app-container {
  opacity: 0;
  animation: fadeIn 1.5s ease-in-out forwards;
  animation-delay: 0.3s; 
}

/* Smooth fade-in animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
