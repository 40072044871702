.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    text-align: center;
    background-color: transparent;
    color: #ffffff;
    padding: 2rem;
  }
  
  .not-found-title {
    font-size: 6rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #ffffff;
  }
  
  .not-found-message {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
