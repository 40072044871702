/* Work Cards Grid */
.work-cards {
    display: flex;
    flex-direction: row; /* Stack cards vertically */
    gap: 40px; 
    flex-wrap: wrap;
    /* max-width: 1200px;  */
  }
  
  @media (max-width: 1000px) {
    .work-cards {
      margin: auto;
    }
  }
  