/* Modal Background */
.opencard-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content Container */
.opencard-modal-content {
  background: #ffffff;
  padding: 24px;
  border-radius: 12px;
  max-width: 800px; /* Adjusted to accommodate side-by-side layout */
  width: 90%;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  animation: fadeIn 0.4s ease-in-out;
}

/* Close Button */
.opencard-close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  background: transparent;
  border: none;
  font-size: 24px;
  color: #000000;
  cursor: pointer;
}

.opencard-close-button:hover {
  color: #ff0000;
}

/* Wrapper for Content */
.opencard-content-wrapper {
  display: flex;
  flex-direction: row; /* Side-by-side layout */
  justify-content: space-between;
  align-items: center;
}

/* Left Side: Description */
.opencard-description {
  flex: 1;
  padding-right: 16px; /* Add spacing between text and image */
  text-align: left;
}

.opencard-description h2 {
  font-size: 1.8rem;
  color: #000000;
  margin-bottom: 16px;
}

.opencard-description p {
  font-size: 1rem;
  color: #555555;
}

/* Right Side: Image */
.opencard-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-image {
  width: 100%;
  max-width: 300px; /* Limit image width */
  height: auto;
  border-radius: 8px;
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .opencard-content-wrapper {
    flex-direction: column; /* Stack content for smaller screens */
  }

  .opencard-description {
    padding-right: 0;
    margin-bottom: 16px; /* Add spacing below the description */
  }

  .modal-image {
    max-width: 100%; /* Allow the image to scale on smaller screens */
  }
}